import React, {useEffect, useState} from "react";
import {AppBar, Box, Button, Menu, MenuItem, Toolbar, Typography} from "@mui/material";
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';

const MenuBar = () => {
    const [anchorEl, setAnchorEl] = React.useState(null);

    return (
        <div>
            <Box sx={{flexGrow: 1}}>
                <AppBar position="static" sx={{backgroundColor:"#282828"}}>
                    <Toolbar>
                        <Typography variant="h6" component="div" sx={{flexGrow: 1,textAlign:"center"}}>
                            سامانه مدیریت جلسات
                        </Typography>
                        {/*<Button color="inherit">ایجاد جلسه جدید</Button>*/}
                    </Toolbar>
                </AppBar>
            </Box>
        </div>
    )
}
export default MenuBar;