import React, {useEffect, useState} from "react";
import './App.css';
import './index.css';
import {BrowserRouter, Routes, Route, useNavigate} from "react-router-dom";
import Font from "./fonts/font.css";
import DashBoard from "./DashBoard";
import axios from "axios";
import Login from "./Login";
import ErrorMsg from "./msg/ErrorMsg";
import SuccsMsg from "./msg/SuccsMsg";
const App = () => {
    const [errorMsg, setErrorMsg] = useState("");
    const [succsMsg, setSuccsMsg] = useState("");
    useEffect(()=>{
        const data={
            token : sessionStorage.getItem("token"),
        }
        axios.post("https://date.tinpod.ir/api/get_userinfo.php", data)
            .then(response => {
                if (response.status === 200) {
                    if (response.data.error === false) {

                    } else {
                        if (!!sessionStorage.getItem("token")){
                            setErrorMsg("توکن غیرمجاز")
                            sessionStorage.clear();
                            window.location.href = "/login";
                        }
                    }
                } else {
                }
            })
            .catch(error => {
            })
    },[])
    return (
        <>
            {errorMsg !== '' ? <ErrorMsg message={errorMsg} openn={true} seterrormsg={setErrorMsg}/> : ''}
            {succsMsg !== '' ? <SuccsMsg message={succsMsg} openn={true} setsuccsmsg={setSuccsMsg}/> : ''}
            <BrowserRouter>
                <Routes>
                    <Route path="/login" element={<Login/>}/>
                    <Route path="/" element={<DashBoard/>}/>
                </Routes>
            </BrowserRouter>
        </>
    )
}

export default App;
