import React, {useEffect, useState} from "react";
import MenuBar from "./MenuBar";
import {useNavigate} from "react-router-dom";
import {
    Avatar, Box, Button, CircularProgress, Dialog, DialogContent, DialogContentText, DialogTitle,
    Grid, InputAdornment, SpeedDial, TextField
} from "@mui/material";
import axios from "axios";
import ErrorMsg from "./msg/ErrorMsg";
import BgImg from "./img/bg1.jpg";
import PersonIcon from '@mui/icons-material/Person';
import ScheduleIcon from '@mui/icons-material/Schedule';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import IconButton from "@mui/material/IconButton";
import CloseIcon from '@mui/icons-material/Close';
import SuccsMsg from "./msg/SuccsMsg";
import DatePicker from "react-multi-date-picker";
import persian from "react-date-object/calendars/persian";
import persian_fa from "react-date-object/locales/persian_fa";
import DateObject from "react-date-object";
import DateRangeIcon from '@mui/icons-material/DateRange';
import "react-multi-date-picker/styles/layouts/mobile.css"
import TimePicker from "react-multi-date-picker/plugins/time_picker";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
const DashBoard = () => {
    const navigate = useNavigate();
    const date = new DateObject({calendar: persian})
    const [meetList, setMeetList] = useState([]);
    const [errorMsg, setErrorMsg] = useState("");
    const [successMsg, setSuccessMsg] = useState("");
    const [loading, setLoading] = useState(false);
    const [dialogAdd, setDialogAdd] = useState(false);
    const [txtDate, setTxtDate] = useState();
    const [txtTime, setTxtTime] = useState();
    const [txtText, setTxtText] = useState("");
    const [update, setUpdate] = useState(false);
    const [prevDay, setPrevDay] = useState("0");
    const [nextDay, setNextDay] = useState("0");
    const [selectedDate, setSelectedDate] = useState("");
    const digits = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"];
    if (!!!localStorage.getItem("token"))
        navigate("/login");
    useEffect(() => {
        setLoading(true);
        const data = {
            token: localStorage.getItem("token"),
            prev: prevDay,
            next: nextDay,
            date: selectedDate,
        }
        console.log(data);
        axios.post("https://date.tinpod.ir/api/get_mettinglist.php", data)
            .then(response => {
                if (response.status === 200) {
                    setLoading(false);
                    if (response.data.error === false) {
                        console.log(response.data);
                        setMeetList(response.data.data);
                        setSelectedDate(response.data.date);
                    } else {
                        if (!!localStorage.getItem("token")) {
                            setErrorMsg("توکن غیرمجاز")
                            localStorage.clear();
                            window.location.href = "/login";
                        }
                    }
                } else {
                }
            })
            .catch(error => {
                setLoading(false);
                setErrorMsg("خطا در اتصال به سرور");
            })
        setUpdate(false);
    }, [update])
    const handle_btn_add_click = () => {
        try {
            setTxtTime(txtDate.format("HH:mm"))
        } catch (ex) {
            setErrorMsg("تاریخ و ساعت نمی تواند خالی باشد");
            return;
        }
        if (!!txtDate) {
            setTxtTime(txtDate.format("HH:mm"))
            setLoading(true);
            const data = {
                token: localStorage.getItem("token"),
                date: txtDate.format("YYYY/MM/DD"),
                message: txtText,
                time: txtDate.format("HH:mm"),
            }
            axios.post("https://date.tinpod.ir/api/insert_metting.php", data)
                .then(response => {
                    setLoading(false);
                    if (response.status === 200) {
                        if (response.data.error === false) {
                            setUpdate(true);
                            setSuccessMsg(response.data.message);
                            setDialogAdd(false);
                        } else
                            setErrorMsg(response.data.message);
                    } else
                        setErrorMsg("خطا در اتصال به سرور");
                })
                .catch(error => {
                    setErrorMsg("خطا در اتصال به سرور");
                })
        } else {
            setErrorMsg("تاریخ و ساعت نمی تواند خالی باشد");
        }

    }
    return (
        <div
            style={{
                backgroundRepeat: "repeat",
                height: "100%",
                backgroundSize: "contain",
                backgroundAttachment: "fixed",

            }}>
            {errorMsg !== '' ? <ErrorMsg message={errorMsg} openn={true} seterrormsg={setErrorMsg}/> : ''}
            {successMsg !== '' ? <SuccsMsg message={successMsg} openn={true} setsuccsmsg={setSuccessMsg}/> : ''}
            <MenuBar/>
            <SpeedDial
                ariaLabel="SpeedDial basic example"
                sx={{position: 'fixed', bottom: 40, right: 16, color: "#424242"}}
                color={"inherit"}
                onClick={() => setDialogAdd(true)}
                icon={<SpeedDialIcon/>}>}
            </SpeedDial>
            <Box sx={{margin: 4}}>
                <Grid container spacing={3}>
                    <Grid item xs={3}>
                        <Button
                            onClick={() => {
                                setPrevDay("0");
                                setNextDay("1");
                                setUpdate(true);
                            }}
                            variant={"contained"}
                            color={"warning"}
                            sx={{maxWidth: "140px"}}
                            fullWidth>
                            <ArrowBackIcon/>

                        </Button>
                    </Grid>
                    <Grid item xs={6}>
                        <Box sx={{textAlign: "center"}}>
                            <DatePicker
                                containerStyle={{
                                    width: "100%"
                                }}
                                value={selectedDate}
                                format="YYYY/MM/DD"
                                type="custom"
                                digits={digits}
                                className="rmdp-mobile"
                                fixMainPosition={true}
                                onChange={(e) => {
                                    setSelectedDate(e.format("YYYY/MM/DD"));
                                    setNextDay("0");
                                    setPrevDay("0");
                                    setUpdate(true);
                                }}
                                render={(stringDate, openCalendar) => {
                                    return (
                                        <div>
                                            <TextField
                                                placeholder="تاریخ"
                                                size={"small"}
                                                autoComplete="off"
                                                value={stringDate}
                                                name="numberformat"
                                                variant="outlined"
                                                InputProps={{
                                                    endAdornment:
                                                        <InputAdornment position="start">
                                                            <IconButton
                                                                aria-label="toggle password visibility"
                                                                onClick={openCalendar}>
                                                                <DateRangeIcon/>
                                                            </IconButton>
                                                        </InputAdornment>,
                                                }}
                                                sx={{marginBottom: 1, textAlign: "center"}}
                                            />
                                        </div>
                                    )
                                }}
                                calendar={persian}
                                locale={persian_fa}
                                calendarPosition="bottom-right"
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={3}>
                        <Box sx={{textAlign: "right"}}>
                            <Button
                                onClick={() => {
                                    setPrevDay("1");
                                    setNextDay("0");
                                    setUpdate(true);
                                }}
                                variant={"contained"}
                                color={"warning"}
                                sx={{maxWidth: "140px"}}
                                fullWidth>
                                <ArrowForwardIcon/>
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
            {meetList.map(item => (
                <Box dir={"rtl"} sx={{margin: 4,}}>
                    <Grid container>
                        <Grid item xs={2} sm={12} md={1}>
                            <Avatar
                                src={item.img}
                                alt={item.name}
                                sx={{width: 76, height: 76, border: "3px solid #041025", backgroundColor: "#e89d14"}}
                            />
                        </Grid>
                        <Grid
                            item
                            xs={12} sm={12} md={11}
                            sx={{
                                backgroundColor: "#041025",
                                borderRadius: "10px",
                                padding: "10px",
                                color: "white",
                                position: "relative",
                            }}>
                            <div
                                style={{
                                    backgroundColor: "#f3c52a",
                                    width: "auto",
                                    position: "absolute",
                                    padding: "6px 16px",
                                    borderRadius: "10px",
                                    top: "-20px",
                                    color: "black",

                                }}>
                                <span style={{color: "#7e7e7e"}}>تاریخ :</span> {item.date} <span
                                style={{color: "#7e7e7e"}}>ساعت : </span> {item.time}
                            </div>
                            <Box sx={{marginTop: 3}}>
                                {item.text}
                            </Box>
                            <Box sx={{marginTop: 3, textAlign: "left", fontSize: "12px", color: "#aba7a7"}}>
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "left",
                                        flexDirection: "row",
                                        justifyItems: "center",
                                        marginBottom: "-15px"
                                    }}>
                                    <div>{item.createdate}</div>
                                    <div><ScheduleIcon sx={{fontSize: "20px"}}/></div>
                                    <div style={{marginRight: "10px"}}>{item.name}</div>
                                    <div><PersonIcon sx={{fontSize: "20px"}}/></div>
                                </div>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            ))}
            <Dialog
                open={dialogAdd}
                onClose={() => {
                    setDialogAdd(false)
                }}>
                <DialogTitle id="dialog_setting_title">
                    <div style={{textAlign: "center", fontSize: "18px"}}>ایجاد جلسه جدید</div>
                    <IconButton
                        aria-label="close"
                        onClick={() => {
                            setDialogAdd(false)
                        }}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}>
                        <CloseIcon/>
                    </IconButton>
                </DialogTitle>
                <DialogContent dividers={'paper'}>
                    <DialogContentText id="scroll-dialog-description" tabIndex={-1}>
                        <DatePicker
                            containerStyle={{
                                width: "100%"
                            }}
                            value={txtDate}
                            format="YYYY/MM/DD"
                            plugins={[
                                <TimePicker hideSeconds/>
                            ]}
                            type="custom"
                            digits={digits}
                            className="rmdp-mobile"
                            fixMainPosition={true}
                            onChange={setTxtDate}

                            render={(stringDate, openCalendar) => {
                                return (
                                    <div>
                                        <TextField
                                            placeholder="تاریخ"
                                            fullWidth
                                            disabled
                                            autoComplete="off"
                                            value={stringDate}
                                            name="numberformat"
                                            id="formatted-numberformat-input"
                                            variant="outlined"
                                            InputProps={{
                                                endAdornment:
                                                    <InputAdornment position="start">
                                                        <IconButton
                                                            aria-label="toggle password visibility"
                                                            onClick={openCalendar}>
                                                            <DateRangeIcon/>
                                                        </IconButton>
                                                    </InputAdornment>,
                                            }}
                                            sx={{marginBottom: 1}}
                                        />
                                    </div>
                                )
                            }}
                            calendar={persian}
                            locale={persian_fa}
                            calendarPosition="bottom-right"
                        />
                        <DatePicker
                            containerStyle={{
                                width: "100%"
                            }}

                            format="HH:mm"
                            plugins={[
                                <TimePicker hideSeconds/>
                            ]}
                            value={txtDate}
                            type="custom"
                            digits={digits}
                            className="rmdp-mobile"
                            fixMainPosition={true}
                            onChange={setTxtDate}

                            render={(stringDate, openCalendar) => {
                                return (
                                    <div>
                                        <TextField
                                            placeholder="ساعت"
                                            fullWidth
                                            disabled
                                            autoComplete="off"
                                            value={stringDate}
                                            name="numberformat"
                                            id="formatted-numberformat-input"
                                            variant="outlined"
                                            sx={{marginBottom: 1}}
                                        />
                                    </div>
                                )
                            }}
                            calendar={persian}
                            locale={persian_fa}
                            calendarPosition="bottom-right"
                        />
                        <TextField
                            autoComplete='off'
                            type='text'
                            fullWidth
                            id="outlined-basic"
                            placeholder="متن"
                            size="medium"
                            value={txtText}
                            onChange={(e) => setTxtText(e.target.value)}
                            variant="outlined" sx={{
                            marginBottom: 1,
                            direction: "rtl"
                        }}/>
                        <Button
                            fullWidth
                            onClick={handle_btn_add_click}
                            variant="contained">ثبت</Button>
                    </DialogContentText>
                </DialogContent>
            </Dialog>
            <Dialog
                open={loading}
                scroll="paper">
                <CircularProgress sx={{margin: 2}}/>
            </Dialog>
        </div>
    )
}
export default DashBoard;
